<template>
  <div class="page">
    <div class="body_center">
      <p>{{tips}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '404',
  data () {
    return {
      time: 4,
      tips: '你想访问的页面不存在... 4秒后返回首页'
    }
  },
  mounted () {
    this.timer()
  },
  methods: {
    timer () {
      if (this.time > 0) {
        this.time--
        this.tips = '你想访问的页面不存在... ' + this.time + '秒后返回首页'
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.tips = ''
        this.$router.replace({ name: 'index', path: '/', query: { random: Math.ceil(Math.random() * 1000) } })
      }
    }
  }
}
</script>
<style>
</style>
